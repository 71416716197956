var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.fashionBanners ? _c('div', [_c('b-carousel', {
    staticStyle: {
      "text-shadow": "1px 1px 2px #333"
    },
    attrs: {
      "id": "carousel-1",
      "interval": 4000,
      "controls": "",
      "indicators": "",
      "background": "#ababab",
      "img-width": "1024",
      "img-height": "280"
    },
    on: {
      "sliding-start": _vm.onSlideStart,
      "sliding-end": _vm.onSlideEnd
    },
    model: {
      value: _vm.slide,
      callback: function callback($$v) {
        _vm.slide = $$v;
      },
      expression: "slide"
    }
  }, _vm._l(_vm.fashionBanners, function (image, index) {
    return _c('b-carousel-slide', {
      key: index,
      staticClass: "fashion-banner-container",
      attrs: {
        "img-src": image.url.fileUrl
      }
    });
  }), 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }