<template>
  <div v-if="fashionBanners">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="280"
      style="text-shadow: 1px 1px 2px #333"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide
        class="fashion-banner-container"
        v-for="(image, index) in fashionBanners"
        :key="index"
        :img-src="image.url.fileUrl"
      >
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "fashion-banner",
  components: {},
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },

  computed: {
    ...mapState({
      fashionBanners: ({ advertisement }) => advertisement.fashionBanners,
      currentPosition: (state) => state.location.currentPosition,
      marketList: (state) => state.fashionMarket.marketList,
    }),
  },

  methods: {
    ...mapActions({
      fetchFashionBannerData: "advertisement/fashionBanner",
    }),

    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },

  async mounted() {
    this.fetchFashionBannerData();
  },
};
</script>

<style scoped>
.text-black-color {
  color: black !important;
  text-decoration: underline;
  font-weight: bold;
}
@media (min-width: 768px) {
  .fashion-banner-container {
    height: auto;
  }
}
.fashion-banner-container {
  height: 350px;
}

@media (max-width: 768px) {
  .downloadhere-mobile-view {
    display: none !important;
  }
}
</style>
<style>
.bg-secondary {
  background-color: white !important;
}
</style>
